export default {
  '10': '待管理员派单',
  '20': '待组长派单',
  '30': '待组员派单',
  '40': '待组员转单',
  '50': '待签到',
  '60': '待维保',
  '70': '待组长审核',
  '80': '待物业审核',
  '11': '管理员接单完成',
  '21': '组长接单完成',
  '31': '组员接单完成',
  '41': '组员转单完成',
  '51': '签到完成',
  '61': '维保完成',
  '71': '组长审核完成',
  '81': '完成',
}

export const orderException = {
  "0": "正常",
  "1": "异常",
}
export const orderExceptionColors = {
  "0": "green",
  "1": "red",
}