export default {
  '10': '保养签到',
  '11': '保养地坑',
  '12': '保养轿厢',
  '13': '保养轿顶',
  '14': '保养机房',
  '15': '保养其他',
  '16': '保养视频',
  '17': '保养签名',
  '20': '维修签到',
  '21': '维修现场',
  '22': '维修视频',
  '23': '维修签名',
}