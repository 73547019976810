export default {
  '10': 'pink',
  '20': 'pink',
  '30': 'pink',
  '40': 'pink',
  '50': 'orange',
  '60': 'orange',
  '70': 'orange',
  '80': 'blue',
  '11': 'pink',
  '21': 'pink',
  '31': 'pink',
  '41': 'pink',
  '51': 'orange',
  '61': 'blue',
  '71': 'blue',
  '81': 'green',
}