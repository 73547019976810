export default [
  { value: '10', name: '待管理员派单'},
  { value: '20', name: '待组长派单'},
  { value: '30', name: '待组员派单'},
  { value: '40', name: '待组员转单'},
  { value: '50', name: '待签到'},
  { value: '60', name: '待维保'},
  { value: '70', name: '待组长审核'},
  { value: '80', name: '待物业审核'},
  { value: '81', name: '已完成'},
]