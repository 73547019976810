// 维保管理
import store from '@/store'
import http from '@/utils/http'

let preApi = '';
if (store.state.mock && process.env.NODE_ENV == 'development') {
  preApi = '/mock';
}

// 添加维修工单
export function addMaintenaceorder(params) {
  return http({
    url: `${preApi}/maintenance/addMaintenaceorder`,
    method: "post",
    data: params
  })
}
// 删除维修工单
export function deleteMaintenanceorder(params) {
  return http({
    url: `${preApi}/maintenance/deleteMaintenanceorder`,
    method: "post",
    data: params
  })
}
// 撤销维修工单
export function cancelMaintenanceorder(params) {
  return http({
    url: `${preApi}/maintenance/cancelMaintenanceorder`,
    method: "post",
    data: params
  })
}
// 查询工单信息列表
export function findMaintenanceorderListByCondition(params) {
  return http({
    url: `${preApi}/maintenance/findMaintenanceorderListByCondition`,
    method: "post",
    data: params
  })
}
// 查询工单详情
export function getMaintenanceorderInfoByorderId(params) {
  return http({
    url: `${preApi}/maintenance/getMaintenanceorderInfoByorderId`,
    method: "post",
    data: params
  })
}
// 提交工单流程
export function commitWorkflow(params) {
  return http({
    url: `${preApi}/maintenance/commitWorkflow`,
    method: "post",
    data: params
  })
}
// 查询工单流程
export function findWorkflowByorderId(params) {
  return http({
    url: `${preApi}/maintenance/findWorkflowByorderId`,
    method: "post",
    data: params
  })
}
// 提交维保检查项信息
export function commitMaintenanceItemResult(params) {
  return http({
    url: `${preApi}/maintenance/commitMaintenanceItemResult`,
    method: "post",
    data: params
  })
}
// 查询维保检查项信息
export function findMaintenanceitemrecordListByOrderId(params) {
  return http({
    url: `${preApi}/maintenance/findMaintenanceitemrecordListByOrderId`,
    method: "post",
    data: params
  })
}
// 提交维保图片信息列表
export function commitMaintenancepic(params) {
  return http({
    url: `${preApi}/maintenance/commitMaintenancepic`,
    method: "post",
    data: params
  })
}
// 查询维保图片信息列表
export function findMaintenancepicByOrderId(params) {
  return http({
    url: `${preApi}/maintenance/findMaintenancepicByOrderId`,
    method: "post",
    data: params
  })
}
// 维保项目--添加
export function addMaintenanceitem(params) {
  return http({
    url: `${preApi}/maintenance/addMaintenanceitem`,
    method: "post",
    data: params
  })
}
// 维保项目--修改
export function modifyMaintenanceitem(params) {
  return http({
    url: `${preApi}/maintenance/modifyMaintenanceitem`,
    method: "post",
    data: params
  })
}
// 维保项目--删除
export function deleteMaintenanceitem(params) {
  return http({
    url: `${preApi}/maintenance/deleteMaintenanceitem`,
    method: "post",
    data: params
  })
}
// 维保项目--列表
export function findMaintenanceitemListByCondition(params) {
  return http({
    url: `${preApi}/maintenance/findMaintenanceitemListByCondition`,
    method: "post",
    data: params
  })
}
// 保养计划--添加
export function addMaintenanceschedule(params) {
  return http({
    url: `${preApi}/maintenance/addMaintenanceschedule`,
    method: "post",
    data: params
  })
}
// 保养计划--修改
export function modifyMaintenanceschedule(params) {
  return http({
    url: `${preApi}/maintenance/modifyMaintenanceschedule`,
    method: "post",
    data: params
  })
}
// 保养计划--删除
export function deleteMaintenanceschedule(params) {
  return http({
    url: `${preApi}/maintenance/deleteMaintenanceschedule`,
    method: "post",
    data: params
  })
}
// 保养计划--列表
export function findMaintenancescheduleListByCondition(params) {
  return http({
    url: `${preApi}/maintenance/findMaintenancescheduleListByCondition`,
    method: "post",
    data: params
  })
}
// 保养计划--列表
export function findMaintenancerecordHistory4Bigdata(params) {
  return http({
    url: `${preApi}/maintenance/findMaintenancerecordHistory4Bigdata`,
    method: "post",
    data: params
  })
}
// 按月导出保养计划
export function exportMaintenanceScheduleExcel(params) {
  params.filename = '保养计划统计.xls';
  return http({
    url: `${preApi}/maintenance/exportMaintenanceScheduleExcel`,
    method: "post",
    data: params,
    responseType: 'blob'
  })
}

// 添加申请资格按需维保单位
export function addMaintainDepAppc(params) {
  return http({
    url: `${preApi}/maintenance/addMaintainDepAppc`,
    method: "post",
    data: params
  })
}
// 更新按需维保单位信息
export function updateMaintainDepAppc(params) {
  return http({
    url: `${preApi}/maintenance/updateMaintainDepAppc`,
    method: "post",
    data: params
  })
}
// 查询按需维保单位信息列表
export function findMaintainDepAppcByCondition(params) {
  return http({
    url: `${preApi}/maintenance/findMaintainDepAppcByCondition`,
    method: "post",
    data: params
  })
}
// 添加申请按需维保项目
export function addMaintainProAppc(params) {
  return http({
    url: `${preApi}/maintenance/addMaintainProAppc`,
    method: "post",
    data: params
  })
}
// 更新按需维保项目信息
export function updateMaintainProAppc(params) {
  return http({
    url: `${preApi}/maintenance/updateMaintainProAppc`,
    method: "post",
    data: params
  })
}
// 查询按需维保单位信息列表
export function findMaintainProAppcByCondition(params) {
  return http({
    url: `${preApi}/maintenance/findMaintainProAppcByCondition`,
    method: "post",
    data: params
  })
}
// 添加问题整改信息
export function addAbarbeitung(params) {
  return http({
    url: `${preApi}/maintenance/addAbarbeitung`,
    method: "post",
    data: params
  })
}
// 更新问题整改信息
export function updateAbarbeitung(params) {
  return http({
    url: `${preApi}/maintenance/updateAbarbeitung`,
    method: "post",
    data: params
  })
}
// 查询问题整改信息列表
export function findAbarbeitungByCondition(params) {
  return http({
    url: `${preApi}/maintenance/findAbarbeitungByCondition`,
    method: "post",
    data: params
  })
}